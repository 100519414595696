import { typedEntries } from "../../utils/objects";
import {
  EntitlementValueObject as EntitlementValueObjectDto,
  InvoiceLineItemView as InvoiceLineItemViewDto,
  InvoiceView as InvoiceViewDto,
  ReclaimEdition as ReclaimEditionDto,
  SubscriptionChange as SubscriptionChangeDto,
  SubscriptionChangeResult as SubscriptionChangeResultDto,
  SubscriptionFrequency as SubscriptionFrequencyDto,
  SubscriptionOption as SubscriptionOptionDto,
  SubscriptionOptions as SubscriptionOptionsDto,
  SubscriptionPrice as SubscriptionPriceDto,
  SupportedCurrency as SupportedCurrencyDto,
  TeamMembershipSummary as TeamMembershipSummaryDto,
  TeamPricingSummary as TeamPricingSummaryDto,
} from "./client";
import {
  EntitlementIntegrations,
  EntitlementTable,
  EntitlementTableRow,
  EntitlementValueObject,
  InvoiceLineItemView,
  InvoiceView,
  ReclaimEdition,
  SubscriptionChange,
  SubscriptionChangeResult,
  SubscriptionFrequencyStr,
  SubscriptionOption,
  SubscriptionOptions,
  SubscriptionPrice,
  SupportedCurrency,
  TeamMembershipSummary,
  TeamPricingSummary,
} from "./Team.types";

export const dtoToReclaimEdition = (dto: ReclaimEditionDto): ReclaimEdition => dto as ReclaimEdition;
export const reclaimEditionToDto = (edition: ReclaimEdition): ReclaimEditionDto => edition as ReclaimEditionDto;

export const dtoToTeamMembershipSummary = (dto: TeamMembershipSummaryDto): TeamMembershipSummary => ({
  ...dto,
  maxUsageEdition: dto.maxUsageEdition ? dtoToReclaimEdition(dto.maxUsageEdition) : undefined,
});

export const dtoToSubscriptionFrequencyStr = (dto: SubscriptionFrequencyDto): SubscriptionFrequencyStr =>
  dto as SubscriptionFrequencyStr;

export const subscriptionFrequencyStrToDto = (subFrequency: SubscriptionFrequencyStr): SubscriptionFrequencyDto =>
  subFrequency as SubscriptionFrequencyDto;

export const dtoToEntitlementValueObject = <T, IS_TOP extends boolean = boolean>(
  dto: EntitlementValueObjectDto
): EntitlementValueObject<T, IS_TOP> =>
  ({
    value: dto.value as unknown as T,
    ...(((!dto.nextEdition || dto.nextValue === undefined) as IS_TOP)
      ? {
          isTop: true,
          nextEdition: void 0,
          nextValue: void 0,
        }
      : {
          isTop: false,
          nextEdition: dtoToReclaimEdition(dto.nextEdition as ReclaimEditionDto),
          nextValue: dto.nextValue as unknown as T,
        }),
  } as unknown as EntitlementValueObject<T, IS_TOP>);

export const dtoToNumberEntitlementValue = (dto: unknown): number => {
  const num = Number(dto);
  return num >= 10000 ? Infinity : num;
};

/**
 * Back-end passes `Infinity` as `"Infinity"`.
 * This function behaves exactly like
 * `dtoToEntitlementValueObject` only it can
 * also parse the string infinity values
 * @param dto The data transfer object
 * @returns a `EntitlementValueObject<number>` object
 */
export const dtoToNumberEntitlementValueObject = <IS_TOP extends boolean = boolean>(
  dto: EntitlementValueObjectDto
): EntitlementValueObject<number, IS_TOP> =>
  ({
    value: dtoToNumberEntitlementValue(dto.value),
    ...(!!dto.nextEdition && dto.nextValue !== undefined
      ? {
          isTop: false,
          nextEdition: dtoToReclaimEdition(dto.nextEdition),
          nextValue: dtoToNumberEntitlementValue(dto.nextValue),
        }
      : {
          isTop: true,
        }),
  } as unknown as EntitlementValueObject<number, IS_TOP>);

const EDITION_MIN_TEAM_SIZE: { [E in ReclaimEdition]?: number } = {
  ENTERPRISE: 100,
};

export const dtoToEntitlementTableRow = (
  dto: Record<string, EntitlementValueObjectDto>,
  // TODO: we need to figure out support for min-team, this is a holdover until then -SG
  edition?: ReclaimEdition
): EntitlementTableRow => ({
  TEAM_SIZE: dtoToEntitlementValueObject({
    ...dto.MAX_TEAM_SIZE,
    value: {
      min: dtoToNumberEntitlementValue((edition && EDITION_MIN_TEAM_SIZE[edition]) || 1),
      max: dtoToNumberEntitlementValue(dto.MAX_TEAM_SIZE.value),
    },
    nextValue:
      typeof dto.MAX_TEAM_SIZE.nextValue === "number"
        ? { min: 1, max: dtoToNumberEntitlementValue(dto.MAX_TEAM_SIZE.nextValue) }
        : null,
  }),
  SCHEDULER_WEEKS: dtoToNumberEntitlementValueObject(dto.SCHEDULER_WEEKS),
  MAX_TASKS: dtoToNumberEntitlementValueObject(dto.MAX_TASKS),
  MAX_CALENDARS: dtoToNumberEntitlementValueObject(dto.MAX_CALENDARS),
  MAX_SYNCS: dtoToNumberEntitlementValueObject(dto.MAX_SYNCS),
  MAX_HABITS: dtoToNumberEntitlementValueObject(dto.MAX_HABITS),
  MAX_SCHEDULING_LINKS: dtoToNumberEntitlementValueObject(dto.MAX_SCHEDULING_LINKS),
  MAX_1_ON_1_ORGANIZE: dtoToNumberEntitlementValueObject(dto.MAX_1_ON_1_ORGANIZE),
  MAX_1_ON_1_ATTEND: dtoToNumberEntitlementValueObject(dto.MAX_1_ON_1_ATTEND),
  SUPPORT: dtoToEntitlementValueObject(dto.SUPPORT),
  SSO: dtoToEntitlementValueObject(dto.SSO),
  CUSTOM_SLACK_STATUS: dtoToEntitlementValueObject(dto.CUSTOM_SLACK_STATUS),

  CUSTOM_BLOCKING: dtoToEntitlementValueObject(dto.CUSTOM_BLOCKING),
  CUSTOM_BLOCKING_CALENDAR_SYNC: dtoToEntitlementValueObject(dto.CUSTOM_BLOCKING_CALENDAR_SYNC),
  CUSTOM_BLOCKING_DECOMPRESSION: dtoToEntitlementValueObject(dto.CUSTOM_BLOCKING_DECOMPRESSION),
  CUSTOM_BLOCKING_HABITS: dtoToEntitlementValueObject(dto.CUSTOM_BLOCKING_HABITS),

  INTEGRATIONS: dtoToEntitlementValueObject<EntitlementIntegrations>(dto.INTEGRATIONS),
  INTEGRATION_SLACK: dtoToEntitlementValueObject(dto.INTEGRATION_SLACK),
  INTEGRATION_CLICKUP: dtoToEntitlementValueObject(dto.INTEGRATION_CLICKUP),
  INTEGRATION_ZOOM: dtoToEntitlementValueObject(dto.INTEGRATION_ZOOM),
  INTEGRATION_GOOGLE_TASKS: dtoToEntitlementValueObject(dto.INTEGRATION_GOOGLE_TASKS),
  INTEGRATION_JIRA: dtoToEntitlementValueObject(dto.INTEGRATION_JIRA),
  INTEGRATION_ASANA: dtoToEntitlementValueObject(dto.INTEGRATION_ASANA),
  INTEGRATION_TODOIST: dtoToEntitlementValueObject(dto.INTEGRATION_TODOIST),
  INTEGRATION_LINEAR: dtoToEntitlementValueObject(dto.INTEGRATION_LINEAR),
  INTEGRATION_GOOGLE_ADD_ON: dtoToEntitlementValueObject(dto.INTEGRATION_GOOGLE_ADD_ON),
  INTEGRATION_MONDAY: dtoToEntitlementValueObject(dto.INTEGRATION_MONDAY),
  INTEGRATION_OFFICE_365: dtoToEntitlementValueObject(dto.INTEGRATION_OFFICE_365),
  INTEGRATION_TRELLO: dtoToEntitlementValueObject(dto.INTEGRATION_TRELLO),
});

export const dtoToEntitlementTable = (
  dto: Record<string, Record<string, EntitlementValueObjectDto>>
): EntitlementTable =>
  typedEntries(dto).reduce((table, [edition, dtoRow]) => {
    table[edition] = dtoToEntitlementTableRow(dtoRow, dtoToReclaimEdition(edition as ReclaimEditionDto));
    return table;
  }, {} as Record<ReclaimEdition, EntitlementTableRow>);

export const dtoToSubscriptionPrice = (dto: SubscriptionPriceDto): SubscriptionPrice => ({
  ...dto,
  isCurrentPlan: !!dto.selected,
});

export const dtoToSubscriptionOption = (dto: SubscriptionOptionDto): SubscriptionOption => ({
  ...dto,
  // Backend isn't sending back Infinity for whatever reason
  maxSeats: dto.maxSeats > 1000 ? Infinity : dto.maxSeats,
  prices:
    dto.prices &&
    typedEntries(dto.prices).reduce((prices, [freq, price]) => {
      prices[freq] = dtoToSubscriptionPrice(price);
      return prices;
    }, {} as SubscriptionOption["prices"]),
});

export const dtoToSubscriptionOptions = (dto: SubscriptionOptionsDto): SubscriptionOptions => ({
  ...dto,
  options: typedEntries(dto.options).reduce((options, [edition, option]) => {
    options[edition] = dtoToSubscriptionOption(option);
    return options;
  }, {} as SubscriptionOptions["options"]),
});

export const dtoToInvoiceLineItemView = (dto: InvoiceLineItemViewDto): InvoiceLineItemView => ({
  ...dto,
  periodStart: dto.periodStart || undefined,
  periodEnd: dto.periodEnd || undefined,
});

export const dtoToSupportedCurrency = (dto: SupportedCurrencyDto): SupportedCurrency => {
  switch (dto) {
    case SupportedCurrencyDto.Usd:
      return "USD";
    case SupportedCurrencyDto.Eur:
      return "EUR";
  }
};
export const supportedCurrencyToDto = (supportedCurrency: SupportedCurrency): SupportedCurrencyDto => {
  switch (supportedCurrency) {
    case "USD":
      return SupportedCurrencyDto.Usd;
    case "EUR":
      return SupportedCurrencyDto.Eur;
  }
};

export const dtoToInvoiceView = (dto: InvoiceViewDto): InvoiceView => ({
  ...dto,
  dueDate: dto.dueDate || undefined,
  lines: dto.lines.map(dtoToInvoiceLineItemView),
  currency: dtoToSupportedCurrency(dto.currency),
});

export const subscriptionChangeToDto = (data: SubscriptionChange): SubscriptionChangeDto => ({
  ...data,
  frequency: subscriptionFrequencyStrToDto(data.frequency),
  edition: reclaimEditionToDto(data.edition),
  promoCode: data.promoCode || null,
});

export const dtoToTeamPricingSummary = <HOMOGENEOUS extends boolean>(
  dto: TeamPricingSummaryDto
): TeamPricingSummary<HOMOGENEOUS> =>
  ({
    ...dto,
    trialEnd: dto.trialEnd || undefined,
    daysLeftInTrial: dto.daysLeftInTrial || undefined,
    subscriptionFrequency: dto.subscriptionFrequency || undefined,
    trial: dto.trial || undefined,
    edition: dto.edition ? dtoToReclaimEdition(dto.edition) : undefined,
    entitlements: dto.entitlements && dtoToEntitlementTableRow(dto.entitlements),
    currency: dto.currency ? dtoToSupportedCurrency(dto.currency) : undefined,
    subscriptionEnd: dto.subscriptionEnd ? new Date(dto.subscriptionEnd) : undefined,
  } as unknown as TeamPricingSummary<HOMOGENEOUS>);

export const dtoToSubscriptionChangeResult = <HOMOGENEOUS extends boolean>(
  dto: SubscriptionChangeResultDto
): SubscriptionChangeResult<HOMOGENEOUS> => ({
  ...dto,
  upcomingInvoice: dto.upcomingInvoice ? dtoToInvoiceView(dto.upcomingInvoice) : undefined,
  membershipSummary: dtoToTeamMembershipSummary(dto.membershipSummary),
  pricingSummary: dtoToTeamPricingSummary(dto.pricingSummary),
});
