import { QUESTS_CONFIG, QUESTS_DIRECTORY } from "../components/quests/quests.consts";
import { QuestConfig, QuestGroup, QuestsProgress } from "../components/quests/quests.types";
import { TimeZone } from "../types";
import { strToDate, WeekdayIndex } from "../utils/dates";
import { typedEntries } from "../utils/objects";
import {
  AssistSettings as AssistSettingsDto,
  DetailedEntitlements as DetailedEntitlementsDto,
  EntitlementActualObject as EntitlementActualObjectDto,
  EntitlementDetails as EntitlementDetailsDto,
  ProductUsageReport as ProductUsageReportDto,
  ReclaimEdition as ReclaimEditionDto,
  SlackSettings as SlackSettingsDto,
  TaskSettings as TaskSettingsDto,
  User as UserDto,
  UserMetadata as UserMetadataDto,
  UserQuests,
  UserSettings as UserSettingsDto,
  TaskAutoWorkflowSettings as TaskAutoWorkflowSettingsDto,
  ZoneId,
  ColorsSettings as ColorsSettingsDto,
  EventColor as EventColorDto,
  CalendarSettings as CalendarSettingsDto,
  FocusSettings as FocusSettingsDto,
  SyncFeatureSettings as SyncFeatureSettingsDto,
  WeeklyReport as WeeklyReportDto,
  ThinCalendar as ThinCalendarDto,
  Settings as SettingsDto,
} from "./client";
import { EventColor, EventColorStr } from "./EventMetaTypes";
import { dtoToTaskDefaults } from "./Tasks.mutators";
import { dtoToNumberEntitlementValue, dtoToReclaimEdition } from "./team/Team.mutators";
import {
  AssistSettings,
  DetailedEntitlements,
  EntitlementDetails,
  Entitlements,
  SlackSettings,
  TaskSettings,
  User,
  ColorsSettings,
  UserMetadata,
  UserSettings,
  UserTimezone,
  TaskAutoWorkflowSettings,
  CalendarSettings,
  FocusSettings,
  SyncSettings,
  WeeklyReportSettings,
  Settings,
} from "./Users";
import { EntitlementActualObject, ProductUsageReport, ProductUsageReportActuals, ThinCalendar } from "./Users.types";

export const dtoToEntitlementActualObject = <T>(dto: EntitlementActualObjectDto): EntitlementActualObject<T> => ({
  ...dto,
  requiredEdition: dtoToReclaimEdition(dto.requiredEdition),
  actualValue: dto.actualValue as T,
  allowedValueForCurrentEdition: dto.allowedValueForCurrentEdition as T,
  requiredEditionValue: dto.requiredEditionValue as T,
});

/**
 * Back-end passes `Infinity` as `"Infinity"`.
 * This function behaves exactly like
 * `dtoToEntitlementActualObject` only it can
 * also parse the string infinity values
 * @param dto The data transfer object
 * @returns a `EntitlementActualObject<number>` object
 */
export const dtoToNumberEntitlementActualObject = (
  dto: EntitlementActualObjectDto
): EntitlementActualObject<number> => ({
  ...dto,
  requiredEdition: dtoToReclaimEdition(dto.requiredEdition),
  actualValue: dtoToNumberEntitlementValue(dto.actualValue),
  allowedValueForCurrentEdition: dtoToNumberEntitlementValue(dto.allowedValueForCurrentEdition),
  requiredEditionValue: dtoToNumberEntitlementValue(dto.requiredEditionValue),
});

export const dtoToProductUsageReportActuals = (
  dto: Record<string, EntitlementActualObjectDto>
): ProductUsageReportActuals => ({
  TEAM_SIZE:
    dto.MAX_TEAM_SIZE &&
    dtoToEntitlementActualObject({
      ...dto.MAX_TEAM_SIZE,
      actualValue: { min: 1, max: dtoToNumberEntitlementValue(dto.MAX_TEAM_SIZE.actualValue) },
      allowedValueForCurrentEdition: {
        min: 1,
        max: dtoToNumberEntitlementValue(dto.MAX_TEAM_SIZE.allowedValueForCurrentEdition),
      },
      requiredEditionValue: { min: 1, max: dtoToNumberEntitlementValue(dto.MAX_TEAM_SIZE.requiredEditionValue) },
    }),
  SCHEDULER_WEEKS: dto.SCHEDULER_WEEKS && dtoToNumberEntitlementActualObject(dto.SCHEDULER_WEEKS),
  MAX_TASKS: dto.MAX_TASKS && dtoToNumberEntitlementActualObject(dto.MAX_TASKS),
  MAX_CALENDARS: dto.MAX_CALENDARS && dtoToNumberEntitlementActualObject(dto.MAX_CALENDARS),
  MAX_SYNCS: dto.MAX_SYNCS && dtoToNumberEntitlementActualObject(dto.MAX_SYNCS),
  MAX_HABITS: dto.MAX_HABITS && dtoToNumberEntitlementActualObject(dto.MAX_HABITS),
  MAX_SCHEDULING_LINKS: dto.MAX_SCHEDULING_LINKS && dtoToNumberEntitlementActualObject(dto.MAX_SCHEDULING_LINKS),
  MAX_1_ON_1_ORGANIZE: dto.MAX_1_ON_1_ORGANIZE && dtoToNumberEntitlementActualObject(dto.MAX_1_ON_1_ORGANIZE),
  MAX_1_ON_1_ATTEND: dto.MAX_1_ON_1_ATTEND && dtoToNumberEntitlementActualObject(dto.MAX_1_ON_1_ATTEND),
  SUPPORT: dto.SUPPORT && dtoToEntitlementActualObject(dto.SUPPORT),
  SSO: dto.SSO && dtoToEntitlementActualObject(dto.SSO),
  CUSTOM_SLACK_STATUS: dto.CUSTOM_SLACK_STATUS && dtoToEntitlementActualObject(dto.CUSTOM_SLACK_STATUS),

  CUSTOM_BLOCKING: dto.CUSTOM_BLOCKING && dtoToEntitlementActualObject(dto.CUSTOM_BLOCKING),
  CUSTOM_BLOCKING_CALENDAR_SYNC:
    dto.CUSTOM_BLOCKING_CALENDAR_SYNC && dtoToEntitlementActualObject(dto.CUSTOM_BLOCKING_CALENDAR_SYNC),
  CUSTOM_BLOCKING_DECOMPRESSION:
    dto.CUSTOM_BLOCKING_DECOMPRESSION && dtoToEntitlementActualObject(dto.CUSTOM_BLOCKING_DECOMPRESSION),
  CUSTOM_BLOCKING_HABITS: dto.CUSTOM_BLOCKING_HABITS && dtoToEntitlementActualObject(dto.CUSTOM_BLOCKING_HABITS),

  INTEGRATIONS: dto.INTEGRATIONS && dtoToEntitlementActualObject(dto.INTEGRATIONS),
  INTEGRATION_SLACK: dto.INTEGRATION_SLACK && dtoToEntitlementActualObject(dto.INTEGRATION_SLACK),
  INTEGRATION_ASANA: dto.INTEGRATION_ASANA && dtoToEntitlementActualObject(dto.INTEGRATION_ASANA),
  INTEGRATION_CLICKUP: dto.INTEGRATION_CLICKUP && dtoToEntitlementActualObject(dto.INTEGRATION_CLICKUP),
  INTEGRATION_GOOGLE_ADD_ON:
    dto.INTEGRATION_GOOGLE_ADD_ON && dtoToEntitlementActualObject(dto.INTEGRATION_GOOGLE_ADD_ON),
  INTEGRATION_GOOGLE_TASKS: dto.INTEGRATION_GOOGLE_TASKS && dtoToEntitlementActualObject(dto.INTEGRATION_GOOGLE_TASKS),
  INTEGRATION_JIRA: dto.INTEGRATION_JIRA && dtoToEntitlementActualObject(dto.INTEGRATION_JIRA),
  INTEGRATION_LINEAR: dto.INTEGRATION_LINEAR && dtoToEntitlementActualObject(dto.INTEGRATION_LINEAR),
  INTEGRATION_MONDAY: dto.INTEGRATION_MONDAY && dtoToEntitlementActualObject(dto.INTEGRATION_MONDAY),
  INTEGRATION_OFFICE_365: dto.INTEGRATION_OFFICE_365 && dtoToEntitlementActualObject(dto.INTEGRATION_OFFICE_365),
  INTEGRATION_TODOIST: dto.INTEGRATION_TODOIST && dtoToEntitlementActualObject(dto.INTEGRATION_TODOIST),
  INTEGRATION_TRELLO: dto.INTEGRATION_TRELLO && dtoToEntitlementActualObject(dto.INTEGRATION_TRELLO),
  INTEGRATION_ZOOM: dto.INTEGRATION_ZOOM && dtoToEntitlementActualObject(dto.INTEGRATION_ZOOM),
});

export const dtoToProductUsageReport = (dto: ProductUsageReportDto): ProductUsageReport => {
  const usageEdition = dtoToReclaimEdition(dto.usageEdition);
  const currentEdition = dtoToReclaimEdition(dto.currentEdition);
  const terminalEdition = dtoToReclaimEdition(dto.terminalEdition);
  const recommendedEdition = dtoToReclaimEdition(dto.recommendedEdition);

  return {
    ...dto,
    usageEdition,
    currentEdition,
    terminalEdition,
    recommendedEdition,
    actuals: dtoToProductUsageReportActuals(dto.actuals),
    terminalActuals: dtoToProductUsageReportActuals(dto.terminalActuals),
  };
};

export const dtoToEntitlementDetails = <N extends string>(dto: EntitlementDetailsDto): EntitlementDetails<N> => ({
  ...dto,
  minimumEdition: dtoToReclaimEdition(dto.minimumEdition as ReclaimEditionDto),
  name: dto.name as N,
});

export const dtoToDetailedEntitlements = (dto: DetailedEntitlementsDto): DetailedEntitlements =>
  typedEntries(dto).reduce((acc, [key, detailedEnt]) => {
    acc[key] = (detailedEnt && dtoToEntitlementDetails(detailedEnt)) as never;
    return acc;
  }, {} as DetailedEntitlements);

export const dtoToQuests = (dto: UserQuests): QuestsProgress => {
  const quests = dto.completedQuests;
  const config: Partial<QuestsProgress> = {};

  Object.keys(QUESTS_DIRECTORY).forEach((groupKey) => {
    config[groupKey] = { quests: {} };
    QUESTS_DIRECTORY[groupKey].forEach((questKey) => {
      const complete = !!quests.find((q) => q === questKey);
      const qCfg: QuestConfig<QuestGroup> = QUESTS_CONFIG[groupKey].quests.find((q) => q.id === questKey);
      const steps = qCfg.steps.map((s) => s.id);
      config[groupKey].quests[questKey] = { complete, steps };
    });
  });

  return config as QuestsProgress;
};

export const dtoToUserTimezone = (dto: ZoneId): UserTimezone => dto as UserTimezone;

export const dtoToUserMetadata = (dto: UserMetadataDto): UserMetadata => ({ ...dto });

export const dtoToAssistSettings = (dto: AssistSettingsDto): AssistSettings => ({
  ...dto,
  conferenceBufferType: dto.conferenceBufferType || "ALL_MEETINGS",
  autoLockForMeetings: dto.autoLockForMeetings || "OFF",
  autoLockForNonMeetings: dto.autoLockForNonMeetings || "OFF",
});

export const dtoToSlackSettings = (dto: SlackSettingsDto): SlackSettings => ({
  ...dto,
  enabled: !!dto.enabled,
});

export const dtoToTaskAutoWorkflowSettings = (dto: TaskAutoWorkflowSettingsDto): TaskAutoWorkflowSettings => ({
  ...dto,
});

export const dtoToTaskSettings = (dto: TaskSettingsDto): TaskSettings => ({
  ...dto,
  enabled: !!dto.enabled,
  googleTasks: !!dto.googleTasks,
  defaults: dtoToTaskDefaults(dto.defaults),
  // cast is to remove erroneous undefined typing from server
  autoWorkflowSettings: dtoToTaskAutoWorkflowSettings(dto.autoWorkflowSettings as TaskAutoWorkflowSettingsDto),
});

export const dtoToEventColor = (dto: EventColorDto): EventColor => EventColor.get(dto as EventColorStr);

export const dtoToColorsSettings = (dto: ColorsSettingsDto): ColorsSettings => ({
  ...dto,
  enabled: !!dto.enabled,
  categoriesEnabled: !!dto.categoriesEnabled,
  // cast is to remove erroneous undefined typing from server
  categories: typedEntries(dto.categories as Record<string, EventColorDto>).reduce((cats, [key, val]) => {
    cats[key] = dtoToEventColor(val);
    return cats;
  }, {} as Record<string, EventColor>),
});

export const dtoToCalendarSettings = (dto: CalendarSettingsDto): CalendarSettings => ({
  ...dto,
  enabled: !!dto.enabled,
});

export const dtoToFocusSettings = (dto: FocusSettingsDto): FocusSettings => ({
  ...dto,
  enabled: !!dto.enabled,
});

export const dtoToSyncSettings = (dto: SyncFeatureSettingsDto): SyncSettings => ({
  ...dto,
  enabled: !!dto.enabled,
});

export const dtoToWeeklyReportSettings = (dto: WeeklyReportDto): WeeklyReportSettings => ({
  ...dto,
  enabled: !!dto.enabled,
});

export const dtoToUserSettings = (dto: UserSettingsDto): UserSettings => ({
  ...dto,
  assistSettings: dtoToAssistSettings(dto.assistSettings),
  slackSettings: dtoToSlackSettings(dto.slackSettings),
  taskSettings: dtoToTaskSettings(dto.taskSettings),
  colors: dtoToColorsSettings(dto.colors),
  calendar: dtoToCalendarSettings(dto.calendar),
  focus: dtoToFocusSettings(dto.focus),
  sync: dtoToSyncSettings(dto.sync),
  weeklyReport: dtoToWeeklyReportSettings(dto.weeklyReport),
});

export const dtoToThinCalendar = (dto: ThinCalendarDto): ThinCalendar => ({
  ...dto,
  timezone: dtoToUserTimezone(dto.timezone),
});

export const dtoToSettings = (dto: SettingsDto): Settings => ({
  ...dto,
  weekStart: dto.weekStart as WeekdayIndex | undefined,
  timezone: dto.timezone as TimeZone | undefined,
});

export const dtoToUser = (dto: UserDto): User => ({
  ...dto,
  // cast is to remove erroneous undefined typing from server
  id: dto.id as string,
  name: dto.name || "",
  // cast is to remove erroneous undefined typing from server
  email: dto.email as string,
  // cast is to remove erroneous undefined typing from server
  timezone: (dto.timezone && dtoToUserTimezone(dto.timezone)) as UserTimezone,
  created: strToDate(dto.created),
  deleted: strToDate(dto.deleted),
  editionAfterTrial: dtoToReclaimEdition(dto.edition),
  // cast is to remove erroneous undefined typing from server
  entitlements: dto.entitlements as Entitlements,
  // cast is to remove erroneous undefined typing from server
  detailedEntitlements: (dto.detailedEntitlements &&
    dtoToDetailedEntitlements(dto.detailedEntitlements)) as DetailedEntitlements,
  // cast is to remove erroneous undefined typing from server
  metadata: (dto.metadata && dtoToUserMetadata(dto.metadata)) as UserMetadata,
  // cast is to remove erroneous undefined typing from server
  features: (dto.features && dtoToUserSettings(dto.features)) as UserSettings,
  // cast is to remove erroneous undefined typing from server
  primaryCalendar: (dto.primaryCalendar && dtoToThinCalendar(dto.primaryCalendar)) as ThinCalendar,
  // cast is to remove erroneous undefined typing from server
  settings: (dto.settings && dtoToSettings(dto.settings)) as Settings,
});
