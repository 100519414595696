import { concat, filter, fromPromise, map, pipe } from "wonka";
import { sourceT } from "wonka/dist/types/src/Wonka_types.gen";
import { Override, TimeZone } from "../types";
import { ObjectEnum } from "../types/enums";
import { dateToStr, WeekdayIndex } from "../utils/dates";
import { camelcase } from "../utils/strings";
import { Envelope } from "./adaptors/ws";
import {
  AutoLock as AutoLockDto, ConferenceBufferType as ConferenceBufferTypeDto, DayHours as DayHoursDto, EntitlementDetails as EntitlementDetailsDto,
  Entitlements as EntitlementsDto,
  HabitTemplateKey as HabitTemplateKeyDto,
  LocalTimeInterval as LocalTimeIntervalDto,
  ProductUsageReport as ProductUsageReportDto,
  QuestType,
  ReferralStats as ReferralStatsDto,
  SchedulingLinkSettings as SchedulingLinkSettingsDto,
  Settings as SettingsDto,
  SubscriptionType,
  TaskAutoWorkflowSettings as TaskAutoWorkflowSettingsDto,
  TaskAutoWorkflowType as TaskAutoWorkflowTypeDto,
  ThinPerson as DtoThinPerson, User as UserDto,
  UserMetadata as UserMetadataDto,
  UserMetadataCompanySize as UserMetadataCompanySizeDto,
  UserMetadataUsecase as UserMetadataUsecaseDto,
  UserProfileDepartment as UserProfileDepartmentDto,
  UserProfileRole as UserProfileRoleDto,
  UserQuests,
  UserSettings as UserSettingsDto,
  UserTrait as UserTraitDto
} from "./client";
import { EventColor } from "./EventMetaTypes";
import { HabitTemplateKey } from "./Habits";
import { TaskDefaults } from "./Tasks";
import { TeamMember } from "./team/Team";
import { reclaimEditionToDto } from "./team/Team.mutators";
import { ReclaimEdition } from "./team/Team.types";
import { TimePolicy } from "./TimeSchemes.types";
import { NotificationKeyStatus, TimeString, TransformDomain } from "./types";
import { dtoToProductUsageReport, dtoToQuests, dtoToUser } from "./Users.mutators";
import { ThinCalendar } from "./Users.types";

export { DateFieldOrder } from "./client";
export type { UserQuests } from "./client";

export type SchedulingLinkSettings = Override<SchedulingLinkSettingsDto, {}>;

export const dtoToSchedulingLinkSettings = (dto: SchedulingLinkSettingsDto): SchedulingLinkSettings => ({ ...dto });
export const schedulingLinkSettingsToDto = (settings: SchedulingLinkSettings): SchedulingLinkSettingsDto => ({
  ...settings,
});

/**
 * @deprecated use `EntitlementType`
 */
export type EntitlementName = keyof EntitlementsDto;
/**
 * @deprecated use `EntitlementValueObject`
 */
export type EntitlementDetails<N extends string> = Override<
  EntitlementDetailsDto,
  {
    name: N;
    minimumEdition: ReclaimEdition;
  }
>;

/**
 * @deprecated use `useUsageData`
 */
export type DetailedEntitlements = { [N in EntitlementName]: EntitlementDetails<N> };

export type ReferralStats = ReferralStatsDto;

export const LOGIN_BASE_URI = process.env.NEXT_PUBLIC_LOGIN_BASE_URI || "/oauth/login";
export const LOGOUT_URI = process.env.NEXT_PUBLIC_LOGOUT_URI || "/logout";

export type QuestTypeStr = `${QuestType}`;

export type UserMetadataUsecase = `${UserMetadataUsecaseDto}`;
export type UserProfileDepartment = `${UserProfileDepartmentDto}`;
export type UserProfileRole = `${UserProfileRoleDto}`;
export type UserMetadataCompanySize = `${UserMetadataCompanySizeDto}`;
export type UserMetadata = Override<
  UserMetadataDto,
  {
    companySize?: UserMetadataCompanySize;
    usecase?: UserMetadataUsecase;
    role?: UserProfileRole;
    department?: UserProfileDepartment | null;
  }
>;

export class UserTrait extends ObjectEnum<UserTraitDto> {
  constructor(public readonly group: string, public readonly feature: string, public readonly label: string) {
    super([group, feature].join("_") as UserTraitDto);
  }
}
export class InterestTrait extends UserTrait {
  static Tasks = new InterestTrait("TASKS", "Tasks");
  static Priorities = new InterestTrait("PRIORITIES", "Priorities");
  static Office365 = new InterestTrait("OFFICE365", "Office365");
  static Calendar = new InterestTrait("CALENDAR", "Calendar");

  static Asana = new InterestTrait("INTEGRATION_ASANA", "Asana");
  static Trello = new InterestTrait("INTEGRATION_TRELLO", "Trello");
  static Todoist = new InterestTrait("INTEGRATION_TODOIST", "Todoist");
  static Jira = new InterestTrait("INTEGRATION_JIRA", "Jira");
  static Linear = new InterestTrait("INTEGRATION_LINEAR", "Linear");
  static ClickUp = new InterestTrait("INTEGRATION_CLICKUP", "ClickUp");
  static Monday = new InterestTrait("INTEGRATION_MONDAY", "Monday");

  static get(feature: string) {
    if (!feature) return undefined;
    return super.get(`INTEREST_${feature.toUpperCase()}`);
  }

  constructor(public readonly feature: string, public readonly label: string) {
    super("INTEREST", feature, label);
  }
}

export class OnboardTrait extends UserTrait {
  static Tasks = new OnboardTrait("TASKS", "Tasks");
  static GoogleTasks = new OnboardTrait("GOOGLE_TASKS", "Google Tasks");
  static PlanItemPrioritized = new OnboardTrait("PLAN_ITEM_PRIORITIZED", "Plan item prioritized");
  static SmartOneOnOne = new OnboardTrait("SMART_ONE_ON_ONES", "Smart 1:1s");
  static BufferTime = new OnboardTrait("BUFFER_TIME", "Buffer time");
  static TasksReindex = new OnboardTrait("TASKS_REINDEX", "Tasks reindex");
  static SchedulingLinks = new OnboardTrait("SCHEDULING_LINKS", "Scheduling Links", "ONBOARD_SCHEDULING_LINKS");
  // This whole thing tries to be WAY too clever with concats and converting snake-case to camel case
  // must specify dtoName here since the trait name is ONBOARD_GOOGLE_ADDON (no _ between ADD and ON)
  // and the onboarding feature object name is googleAddOn (capital O)
  static GoogleAddon = new OnboardTrait("GOOGLE_ADDON", "Google calendar add-on", "googleAddOn");

  static get(feature: string) {
    if (!feature) return undefined;
    return super.get(`ONBOARD_${feature.toUpperCase()}`);
  }

  static completed(user: User | null, feature: OnboardTrait) {
    return !!user?.features.onboard?.[feature.dtoName || camelcase(feature.feature)];
  }

  /**
   * User traits related to onboarding
   * @param feature the feature name from UserTraitDto WITHOUT `ONBOARD_` - that will be added automatically
   * @param label a label for the onboarding
   * @param dtoName the DTO name of the onboarding
   */
  constructor(public readonly feature: string, public readonly label: string, public readonly dtoName?: string) {
    super("ONBOARD", feature, label);
  }
}

export type ThinPerson = DtoThinPerson;

/**
 * @deprecated use `useUsageData`
 */
export type Entitlements = Override<EntitlementsDto, {}>;

export type LocalTimeInterval = Override<
  LocalTimeIntervalDto,
  {
    duration?: number;
    start: TimeString;
    end: TimeString;
  }
>;

export type DayHours = Override<
  DayHoursDto,
  {
    intervals: LocalTimeInterval[];
  }
>;

export type AutoLock = `${AutoLockDto}`;

export type AssistSettings = Override<
  UserSettingsDto["assistSettings"],
  {
    travel?: boolean;
    otherTravelDuration?: number;
    conferenceBuffer?: boolean;
    conferenceBufferDuration?: number;
    conferenceBufferPrivate?: boolean;
    focus?: boolean;
    autoLockForMeetings: AutoLock;
    autoLockForNonMeetings: AutoLock;
    conferenceBufferType: ConferenceBufferType;
  }
>;

export type SlackSettings = Override<
  UserSettingsDto["slackSettings"],
  {
    readonly enabled: boolean;
  }
>;

export type TaskAutoWorkflowType = `${TaskAutoWorkflowTypeDto}`;

export type ConferenceBufferType = `${ConferenceBufferTypeDto}`;

export type TaskAutoWorkflowSettings = Override<
  TaskAutoWorkflowSettingsDto,
  {
    category?: TaskAutoWorkflowType;
  }
>;

export const dtoToTaskAutoWorkflowSettings = (dto: TaskAutoWorkflowSettingsDto): TaskAutoWorkflowSettings => ({
  ...dto,
});

export type TaskSettings = Override<
  UserSettingsDto["taskSettings"],
  {
    readonly enabled: boolean;
    readonly googleTasks: boolean;
    defaults: TaskDefaults;
    autoWorkflowSettings: TaskAutoWorkflowSettings;
  }
>;

export type ColorsSettings = Override<
  Omit<UserSettingsDto["colors"], "projectsEnabled" | "priorities">,
  {
    readonly enabled: boolean;
    readonly categoriesEnabled: boolean;
    categories: Record<string, EventColor>;
  }
>;

export type CalendarSettings = Override<
  UserSettingsDto["calendar"],
  {
    readonly enabled: boolean;
  }
>;

export type FocusSettings = Override<
  UserSettingsDto["focus"],
  {
    readonly enabled: boolean;
  }
>;

export type SyncSettings = Override<
  UserSettingsDto["sync"],
  {
    readonly enabled: boolean;
  }
>;

export type WeeklyReportSettings = Override<
  UserSettingsDto["weeklyReport"],
  {
    readonly enabled: boolean;
    sendReport?: boolean;
  }
>;

export type CommonPerson = User | ThinPerson | TeamMember;

export type UserSettings = Override<
  // TODO: remove this Omit as soon as the backend has removed this flag
  Omit<UserSettingsDto, "asana" | "schedulingLink" | "projectSettings" | "projects" | "priorities">,
  {
    assistSettings: AssistSettings;
    slackSettings: SlackSettings;
    taskSettings: TaskSettings;
    colors: ColorsSettings;
    calendar: CalendarSettings;
    focus: FocusSettings;
    sync: SyncSettings;
    weeklyReport: WeeklyReportSettings;
    timePolicies: Record<string, TimePolicy>; // FIXME (IW): use TimePolicyType as key
  }
>;

export type Settings = Override<
  SettingsDto,
  {
    weekStart?: WeekdayIndex;
    timezone?: TimeZone;
  }
>;

export type UserFeature = keyof UserSettings;

// TODO this is going to be kinda messy to maintain... to keep in sync with UserSettings (ma)
export type UserFeatureFlag = {
  modifyCalendar: boolean;
  assist: boolean;

  "defaultSyncSettings.workingHours": boolean;

  "assistSettings.focus": boolean;
  "assistSettings.travel": boolean;
  "assistSettings.conferenceBuffer": boolean;
  "assistSettings.conferenceBufferPrivate": boolean;

  "taskSettings.enabled": boolean;
  "slackSettings.enabled": boolean;
  "priorities.enabled": boolean;

  "colors.enabled": boolean;
  "colors.prioritiesEnabled": boolean;
  "colors.categoriesEnabled": boolean;

  "calendar.enabled": boolean;
  "focus.enabled": boolean;
  "billing.enabled": boolean;

  "asana.enabled": boolean;

  "appNotifications.enabled": boolean;
  "appNotifications.unscheduledPriority": boolean;

  "weeklyReport.sendReport": boolean;
};

export type UserTimezone = {
  id: string;
  displayName: string;
  abbreviation: string;
};

export type User = Override<
  Omit<
    UserDto,
    // These are unused and can be removed by the backend
    "principal" | "provider" | "refCode" | "admin" | "likelyPersonal" | "locale"
  >,
  {
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly timezone: UserTimezone;
    readonly trackingCode?: string;
    readonly likelyPersonal?: UserDto["likelyPersonal"];
    readonly created?: Date;
    readonly deleted?: Date;

    readonly edition: ReclaimEdition;
    readonly editionAfterTrial: ReclaimEdition;
    /**
     * @deprecated use `useUsageData`
     */
    readonly entitlements: Entitlements;
    /**
     * @deprecated use `useUsageData`
     */
    readonly detailedEntitlements: DetailedEntitlements;

    readonly metadata: UserMetadata;

    features: UserSettings;
    primaryCalendar?: ThinCalendar;
    settings?: Settings;
  }
>;

export const dtoToThinPerson = (dto: DtoThinPerson): ThinPerson => ({ ...dto });
export const thinPersonToDto = (data: ThinPerson): DtoThinPerson => ({ ...data });

const ProductUsageSubscription = {
  subscriptionType: SubscriptionType.ProductUsage,
};

function userToDto(user: Partial<User>): Partial<UserDto> {
  // TODO (IW): Fix nested types so this doesn't have to be casted as `any`
  const dto: Partial<UserDto> = {
    ...user,
    features: user.features && {
      ...(user.features as unknown as UserSettingsDto),
      schedulingLinks: schedulingLinkSettingsToDto(user.features.schedulingLinks),
    },
    created: dateToStr(user.created),
    deleted: dateToStr(user.deleted),
    // TODO (IW): Figure out readonly annotations, don't serialize readonly stuff
    locale: undefined,
    edition: user.edition && reclaimEditionToDto(user.edition),
    editionAfterTrial: user.editionAfterTrial && reclaimEditionToDto(user.editionAfterTrial),
    metadata: {
      ...user.metadata,
      companySize: user.metadata?.companySize && UserMetadataCompanySizeDto[user.metadata?.companySize],
      usecase: user.metadata?.usecase && UserMetadataUsecaseDto[user.metadata?.usecase],
      role: (user.metadata?.role as UserProfileRoleDto) || undefined,
      department: user.metadata?.department as UserProfileDepartmentDto | undefined | null,
    },
  };

  return dto;
}

const QuestSubscription = {
  subscriptionType: SubscriptionType.Quest,
};

export class UsersDomain extends TransformDomain<User, UserDto> {
  resource = "User";
  cacheKey = "users";
  pk = "id";

  public deserialize = dtoToUser;
  public serialize = userToDto;

  watchQuestsWs$ = pipe(
    this.ws.subscription$$(QuestSubscription),
    filter((envelope) => !!envelope.data),
    map((envelope) => envelope.data)
  );

  fetchAndWatchQuests$$ = () =>
    pipe(
      concat<UserQuests>([fromPromise<UserQuests>(this.api.users.getCompletedQuests()), this.watchQuestsWs$]),
      map((quests) => quests as unknown as UserQuests)
    );

  getCurrentUser = this.manageErrors(this.deserializeResponse(this.api.users.current));

  updateCurrentUser = this.manageErrors(
    this.deserializeResponse((user: Partial<User>) => this.api.users.patch3(this.serialize(user) as UserDto))
  );

  deleteCurrentUser = this.manageErrors(this.api.users.delete6);

  addInterest = this.manageErrors(
    this.deserializeResponse((trait: InterestTrait) => this.api.users.addTrait(trait.key, {}))
  );

  listContacts = this.manageErrors(this.api.users.getContacts);

  claimRewards: (tier: number) => Promise<ReferralStats> = this.manageErrors((tier) =>
    this.api.users.claimRewards({ claim: tier })
  );

  inviteContacts = this.manageErrors(this.api.users.inviteContacts);

  hardDowngrade = this.typedManageErrors(async () => dtoToProductUsageReport(await this.api.users.hardDowngrade()));

  referrals = this.manageErrors(this.api.users.referrals);

  getCompletedQuests = this.typedManageErrors(async () => {
    const response = await this.api.users.getCompletedQuests();
    return dtoToQuests(response);
  });

  completeQuest = this.typedManageErrors(async (quest: QuestTypeStr) => {
    const response = await this.api.users.completeQuest({ questType: quest as QuestType });
    return dtoToQuests(response);
  });

  authRedirect(
    provider: string,
    hint?: string | false,
    state?: { [key: string]: string | null | number | undefined } | null
  ) {
    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    const authUrl = new URL(`${LOGIN_BASE_URI}/${provider}`, window.location.href);
    if (state) authUrl.searchParams.append("state", JSON.stringify(state));

    if (false === hint) {
      authUrl.searchParams.append("prompt", "select_account");
    } else if (typeof hint === "string") {
      authUrl.searchParams.append("login_hint", hint);
    }

    window.location.href = authUrl.toString();
  }

  logout() {
    const url = new URL(LOGOUT_URI, window.location.href);
    window.location.href = url.toString();
  }

  ssoLogin = this.typedManageErrors(this.api.sso.findProvider);
  downgradeHardly = this.typedManageErrors(async () => dtoToProductUsageReport(await this.api.users.hardDowngrade()));
  usage = this.typedManageErrors(async () => dtoToProductUsageReport(await this.api.users.productUsage()));

  productUsageWs$ = pipe(
    this.ws.subscription$$(ProductUsageSubscription) as sourceT<Envelope<ProductUsageReportDto>>,
    filter((envelope) => !!envelope.data),
    map((envelope) => dtoToProductUsageReport(envelope.data))
  );

  watchProductUsage$$ = () => this.productUsageWs$;

  onboardUser = this.typedManageErrors(
    (
      habits: HabitTemplateKey[],
      hasTravelBuffer: boolean,
      hasDecompressionTime: boolean,
      userId: string
    ): Promise<User | void> => {
      const notificationKey = this.generateUid("User", userId);
      this.expectChange(notificationKey, userId, {}, true);

      return this.api.users
        .onboarded(
          { habitTemplateKeys: habits as HabitTemplateKeyDto[], hasTravelBuffer, hasDecompressionTime },
          { notificationKey }
        )
        .then((user) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return this.deserialize(user);
        })
        .catch(() => {
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        });
    }
  );
}

export const defaultTimePolicy = (): TimePolicy => ({
  dayHours: {
    MONDAY: {
      intervals: [{ start: "09:00:00", end: "17:00:00" }],
    },
    TUESDAY: {
      intervals: [{ start: "09:00:00", end: "17:00:00" }],
    },
    WEDNESDAY: {
      intervals: [{ start: "09:00:00", end: "17:00:00" }],
    },
    THURSDAY: {
      intervals: [{ start: "09:00:00", end: "17:00:00" }],
    },
    FRIDAY: {
      intervals: [{ start: "09:00:00", end: "17:00:00" }],
    },
  },
});
