import {
  Duration as DurationDto, TaskCategory as TaskCategoryDto, TimePolicy as TimePolicyDto,
  TimePolicyType as TimePolicyTypeDto,
  TimeScheme as TimeSchemeDto, TimeSchemeCreateRequest as TimeSchemeCreateRequestDto, TimeSchemeUpdateRequest as TimeSchemeUpdateRequestDto
} from "./client";
import {
  TaskCategory,
  TimePolicy,
  TimePolicyType,
  TimeScheme,
  TimeSchemeCreateRequest,
  TimeSchemeUpdateRequest
} from "./TimeSchemes.types";

export const dtoToTimePolicyType = (dto: TimePolicyTypeDto) => dto as TimePolicyType;
export const timePolicyTypeToDto = (type: TimePolicyType) => type as TimePolicyTypeDto;

export const taskCategoryToDto = (category: TaskCategory) => category as TaskCategoryDto;

export const timePolicyToDto = (timePolicy: TimePolicy): TimePolicyDto => ({
  ...timePolicy,
  dayHours: Object.entries(timePolicy.dayHours).reduce((dhs, [day, dh]) => {
    dhs[day] = {
      ...dh,
      intervals: dh.intervals.map(({ duration, ...interval }) => ({
        ...interval,
        // swagger thinks this is an object, but the backend always uses numbers here.
        duration: duration as DurationDto,
      })),
    };
    return dhs;
  }, {} as TimePolicyDto["dayHours"]),
});

export const dtoToTimePolicy = (dto: TimePolicyDto): TimePolicy => ({
  ...dto,
  dayHours: Object.entries(dto.dayHours).reduce((dhs, [day, dh]) => {
    dhs[day] = {
      ...dh,
      intervals: dh.intervals.map(({ duration, ...interval }) => ({
        ...interval,
        // swagger thinks this is an object, but the backend always uses numbers here.
        duration: duration as number,
      })),
    };
    return dhs;
  }, {} as TimePolicy["dayHours"]),
});

export const dtoToTimeScheme = (dto: TimeSchemeDto): TimeScheme => ({
  ...dto,
  policy: dtoToTimePolicy(dto.policy),
  policyType: dtoToTimePolicyType(dto.policyType),
});

export const timeSchemeToDto = (scheme: TimeScheme): TimeSchemeDto => ({
  ...scheme,
  policy: timePolicyToDto(scheme.policy),
  policyType: timePolicyTypeToDto(scheme.policyType),
});

export const timeSchemeCreateRequestToDto = (request: TimeSchemeCreateRequest): TimeSchemeCreateRequestDto => ({
  ...request,
  policy: timePolicyToDto(request.policy),
  policyType: timePolicyTypeToDto(request.policyType),
  taskCategory: request.taskCategory ? taskCategoryToDto(request.taskCategory) : null,
});

export const timeSchemeUpdateRequestToDto = (request: TimeSchemeUpdateRequest): TimeSchemeUpdateRequestDto => ({
  ...request,
  policy: timePolicyToDto(request.policy),
  policyType: timePolicyTypeToDto(request.policyType),
  taskCategory: request.taskCategory ? taskCategoryToDto(request.taskCategory) : null,
});
